/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import "./src/styles/global.css";
import React from "react";
import GoogleAnalyticsService from './src/services/GoogleAnalyticsService';
import useGlobalClickListener from './src/hooks/useGlobalClickListener';

export const onClientEntry = () => {
  GoogleAnalyticsService.initialize();
};

export const onRouteUpdate = ({ location }) => {
  GoogleAnalyticsService.trackPageView(location);
}

const RootWrapper = ({ children }) => {
  useGlobalClickListener();
  return <>{children}</>;
};

export const wrapRootElement = ({ element }) => {
  return <RootWrapper>{element}</RootWrapper>;
};