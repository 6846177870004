module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://admin.burlingtonoht.ca/graphql","protocol":"http","hostingWPCOM":false,"useACF":true,"includedRoutes":["**/categories","**/posts","**/pages","**/notifications","**/media","**/tags","**/taxonomies","**/users","**/wpMenu","**/service","**/services"],"production":{"allow404Images":true},"schema":{"perPage":20,"requestConcurrency":5,"previewRequestConcurrency":2},"type":{"MediaItem":{"createFileNodes":false}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#666666","theme_color":"#666666","display":"minimal-ui","icon":"src/images/app-icon-BOHT.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"373f51eaf81c2e050201424ba3d61e69"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"xs":"(max-width: 320px)","sm":"(max-width: 992px)","md":"(max-width: 1200px)","l":"(max-width: 1330px)","xl":"max-width: 1440px"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/app/src/intl","languages":["en"],"defaultLanguage":"en","redirect":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
