
// keys: CookieConsent, Cookie, Banner

const CookieService = {
  setAttribute: function (key, value) {
    localStorage.setItem(key, JSON.stringify(value))
  },
  getAttribute: function (key) {
    return JSON.parse(localStorage.getItem(key) || "[]")
  },
  isSet: function (key) {
    return localStorage.getItem(key) !== null
    },
  removeAttribute: function (key) {
    localStorage.removeItem(key)
  },
  clear: function () {
    localStorage.clear()
  }
}

export default CookieService
