import TagManager from "react-gtm-module"
import ReactGA4 from "react-ga4"
import CookieService from "./CookieService"

class GoogleAnalyticsService {
  static initialize() {
    if (this.isEnabled()) {
      const tagManagerArgs = {
        gtmId: "GTM-KM5JP3R",
        dataLayer: {
          pageType: window.pageType,
        },
      }
      // Google Analytics
      ReactGA4.initialize("G-D29YESSVR8", { debug_mode: true })
      
      // Google Tag Manager
      TagManager.initialize(tagManagerArgs)
    }
  }

  static trackPageView(location) {
    const pageDetails = { page_path: location.pathname }
    console.log("pageview")
    ReactGA4.send("pageview", pageDetails)
  }

  static trackEvent(eventName, eventParams) {
    ReactGA4.event(eventName, eventParams)
  }

  static isEnabled() {
    return Boolean(CookieService.getAttribute("CookieConsent")?.consent)
  }
}

export default GoogleAnalyticsService
