import GoogleAnalyticsService from '../services/GoogleAnalyticsService';
import { useEffect } from 'react';

function useGlobalClickListener() {
  useEffect(() => {
    function handleClick(event) {
      const target = event.target;

      if (target instanceof HTMLButtonElement || target instanceof HTMLAnchorElement) {
        const eventName = 'click';
        const eventParams = {
          category: 'User Event',
          label: target.innerText || "",
        };

        GoogleAnalyticsService.trackEvent(eventName, eventParams);
      }
    }

    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, []);
}

export default useGlobalClickListener;
